export default {
    markets: [],
    marketData: [],
    marketsList: [],
    tableFilterState: '',
    sortKey: '',
    sortOrder: true,
    sortIndicator: '',
    currentMarketIndex: null,
    marketId: null,
    top4Asin: [],
    // < --- OVERVIEW --- >
    allMarketSales: null,
    allMarketRevenue: null,
    allReviews: null,
    reviewsCount: null,
    // < --- OVERVIEW --- >
    top4Indicator:''
}