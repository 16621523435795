import axios from 'axios'

async function registerUser(userObj) {
    try {
        const userData = await axios({
            url: 'https://thawing-basin-94873.herokuapp.com/users/register',
            method: 'post',
            data: userObj,
            withCredentials: true
        })
        sessionStorage.setItem('isLogin','true');
        return userData.data
    } catch (e) {
        return false;
    }
}

async function logOutUser() {
    try {
        const msgFromDb = await axios({
            url: 'https://thawing-basin-94873.herokuapp.com/users/logout',
            method: 'post',
            withCredentials: true
        })
        return msgFromDb.data.success === true
    } catch (e) {
        console.log(e)
    }
}

async function loginUser(userObj) {
    try {
        const userData = await axios({
            url: 'https://thawing-basin-94873.herokuapp.com/users/login',
            method: 'post',
            data: userObj,
            withCredentials: true
        })
        const data = userData.data.data
        sessionStorage.setItem('isLogin','true');
        return data
    } catch (e) {
        console.log(e)
    }
}

async function getUserData(userObj) {
    try {
        const userData = await axios({
            url: 'https://thawing-basin-94873.herokuapp.com/users/data',
            method: 'get',
            data: userObj,
            withCredentials: true
        })
        const data = userData.data
        return data
    } catch (e) {
        return e.response.status
    }
}

export default {
    registerUser,
    loginUser,
    logOutUser,
    getUserData
}
