export default {
    trackedProducts: (state) => (term) => {
        if (term === 'all') return state.marketData;
        return state.marketData.filter(market => market.tracked === term);
    },

    //TODO:         FIX sorting, right now i found a way to make ascending
    //TODO:               order work, by filter to descending first....
    sortByParam: (state) => (arr, key, ascending) => {
        let indicate = ascending ? 1 : -1; //ascending true = low to high.
        arr.sort((a, b) => {
            if (!a[key]) return 1;
            if (a[key] === b[key]) return 0;
            return a[key] > b[key] ? 1 * indicate : -1 * indicate;
        });
    }
}