export default {
    LOGIN_USER: ((state, data) => {
        Object.assign(state.user, data)
    }),
    REGISTER_USER: ((state, data) => {
        Object.assign(state.newUser, data)
    }),

    RESET_USER: ((state) => {
        state.user = {};
    })
}
