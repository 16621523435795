<template>
    <div>
      <router-view/>
    </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    if(!sessionStorage.getItem('isLogin')){
      this.$router.push('/login')
    }
  }
}
</script>
