import router from './index'

const routes = [
    {
        path: '/', component: () => import('../layouts/MainLayout.vue'),
        children: [
            {path: '/', component: () => import('../views/Home')},
            {path: '/market-tracker', component: () => import('../views/market-tracker/MarketTracker')},
            {path: '/create-new-market', component: () => import('../views/market-tracker/CreateNewMarket')}
        ]
    },
    {
        path: '/b', component: () => import('../layouts/BlankLayout.vue'),
        children: [
            {path: '/login', component: () => import('../views/auth/Login')},
            {path: '/register', component: () => import('../views/auth/Register')},
            {path:'', component: ()=> import('../views/Home')},
        ]
    },
    // router.beforeEach((to, from, next) => {
    //     const user = firebaseInstance.firebase.auth().currentUser
    //     if (user && to.meta.authNotRequired || !user & !to.meta.authNotRequired) {
    //         console.log(user)
    //         const path = !user ? '/signin' : '/';
    //
    //         console.log(path)
    //         return next(path)
    //     }
    //     next()
    // })
]

export default routes
