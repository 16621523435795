import { createStore } from 'vuex'
import auth from './auth'
import marketTracker from './market-tracker'

export default createStore({
  modules: {
    auth,
    marketTracker
  }
})
