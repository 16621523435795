import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

/* npm library for charts https://chartkick.com/vue */
//doesnt work for now Do not use it
// import Chartkick from 'vue-chartkick'
// import Chart from 'chart.js'
// use(Chartkick.use(Chart))
// app.use(Chartkick.use(Chart))

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app')
