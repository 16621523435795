import axios from 'axios'


function getUserMarketsDetails(marketId, setState) {
    try {
        return axios({
            url: `https://thawing-basin-94873.herokuapp.com/markets/details/${marketId}`,
            method: 'get',
            withCredentials: true
        })
            .then(response => {
                return response.data
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    setState(err.response.status)
                }
            })
    } catch (e) {
        console.log(e)
    }
}
function newMarketToServer(marketData, cb) {
    try {
        axios({
            url: 'https://thawing-basin-94873.herokuapp.com/markets/new',
            method: 'post',
            data: marketData,
            withCredentials: true
        })
            .then(response => {
                cb(response)
            })
    } catch (e) {
        console.log(e)
    }
}
function getSpecificMarketInfo(marketId) {
    try {
        return axios({
            url: `https://thawing-basin-94873.herokuapp.com/markets/${marketId}`,
            method: 'get',
            withCredentials: true
        })
            .then(response => {
                return response.data
            })
    } catch (e) {
        console.log(e)
    }
}
function changeCol(mid, asins, col, cb) {
  try {
    axios({
      url: `https://thawing-basin-94873.herokuapp.com/markets/${mid}`,
      method: 'put',
      data: {
        col: col,
        value: 1,
        asins
      },
      withCredentials: true
    })
        .then(response => {
          cb()
        })
  } catch (e) {
    console.log(e)
  }
}
function setUserProduct(self, asin, value) {
    try {
        return axios({
            url: `https://thawing-basin-94873.herokuapp.com/markets/${self.marketId}/asins/myproduct`,
            method: 'put',
            data: {
                value,
                asin,
                currentUserProduct: self.currentUserProduct.asin
            },
            withCredentials: true
        })
            .then(response => {
                return response
            })
    } catch (e) {
        console.log(e)
    }
}
function deleteMarkets(self, markets, cb) {
    try {
        axios({
            url: `https://thawing-basin-94873.herokuapp.com/markets/${self.marketId}`,
            method: 'delete',
            data: {
                markets
            },
            withCredentials: true
        })
            .then(response => {
                cb()
            })
    } catch (e) {
        console.log(e)
    }
}
function getAsinPropRecords(asin, prop, marketId, setState) {
    try {
        axios({
            url: `https://thawing-basin-94873.herokuapp.com/markets/${marketId}/asins/${asin}/props/${prop}`,
            method: 'get',
            withCredentials: true
        })
            .then(response => {
                setState(response.data)
            })
    } catch (e) {
        console.log(e)
    }
}
function getAsinChartData(asin, prop, marketId) {
    try {
       return axios({
            url: `https://thawing-basin-94873.herokuapp.com/markets/${marketId}/asins/${asin}/chart-data`,
            method: 'get',
            withCredentials: true
        })
            .then(response => {
                return response.data;
            })
    } catch (e) {
        console.log(e)
    }
}

async function getNumberOfVariations(asin) {
    try {
        const response = await axios({
            url: 'https://rocky-caverns-36963.herokuapp.com/keepa/variations',
            method: 'post',
            data: {asin}
        })
        if(response.data.length) return response.data.length;
        else return response.data;
    } catch (e) {
       return e;
    }
}

async function getTop5ChartData(asins, prop, marketId) {
    try {
        const response = await axios({
            url: `https://thawing-basin-94873.herokuapp.com/markets/${marketId}/chart-top-5-data/asins/${asins}/prop/${prop}`,
            method: 'get',
            withCredentials: true
        })
        return response.data
    } catch (e) {
        console.log(e)
    }
}
export default {
    newMarketToServer,
    getUserMarketsDetails,
    getSpecificMarketInfo,
    changeCol,
    deleteMarkets,
    getAsinChartData,
    setUserProduct,
    getTop5ChartData,
    getNumberOfVariations
}
