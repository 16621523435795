//TODO : STOP REFERENCE OBJECTS TO A DIFFERENT MEMORY LOCATION.

export default {
    SET_MARKET_INFO: ((state, data) => {
        Object.keys(data).forEach(key => {
            state.marketData.push(data[key]);
        })
    }),
    CLEAR_MARKET_INFO: ((state) => {
        state.marketData = []
    }),
    SET_MARKETS_LIST: ((state, data) => {
        data.forEach(datum => {
            state.marketsList.push(datum)
        })
    }),
    CLEAR_MARKETS_LIST: (state => {
        state.marketsList = [];
    }),
    SET_CHOSEN_MID: ((state, id) => {
        state.marketId = id;
    }),
    CLEAR_CHOSEN_MID: ((state) => {
        state.marketId = null;
    }),
    SET_CHOSEN_MID_INDEX: ((state, index) => {
        state.currentMarketIndex = index;
    }),
    SET_TOP_4_ASIN: ((state, top4Asin) => {
        state.top4Asin = [...top4Asin];
    }),
    CLEAR_TOP_4_ASIN: (state => {
        state.top4Asin = [];
    }),
    // <     ---------     OVERVIEW    ---------     >
    SET_allMarketSales: ((state, allMarketSales) => {
        state.allMarketSales = allMarketSales;
    }),
    SET_allMarketRevenue: ((state, allMarketRevenue) => {
        state.allMarketRevenue = allMarketRevenue;
    }),
    SET_allReviews: ((state, allReviews) => {
        state.allReviews = allReviews;
    }),
    SET_reviewsCounts: ((state, reviewsCount) => {
        state.reviewsCount = reviewsCount;
    }),
    // <     ---------     OVERVIEW    ---------     }>

    // <     ---------     FILTERS    ---------     }>
    SET_TABLE_FILTER_STATE: ((state, str) => {
        // console.log(str,'state')
        state.tableFilterState = str;
    }),
    SET_SORT_ORDER: ((state, order) => {
        // console.log(order,'order')
        state.sortOrder = order;
    }),
    SET_SORT_KEY: ((state, key) => {
        // console.log(key,'key')
        state.sortKey = key;
    }),
    SET_SORT_INDICATOR: ((state, indicator) => {
        // console.log(indicator,'indicator')
        state.sortIndicator = indicator;
    }),
    // <     ---------     FILTERS    ---------     }>

    // TODO: should've named it SET_ANOTHER_STUPID_INDICATOR ~~~~~ find a way for watcher for array/objects
    SET_TOP_4_INDICATOR: ((state, indicator) => {
        state.top4Indicator = indicator;
    }),
}

//TODO : SET A MUTATION WHICH INSERTS THE MARKETS LIST TO STATE.