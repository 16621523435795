import serverApi from "../../middleware/serverApi";

export default {
    async getDataFromDb({commit}, marketId) {

        // TODO: this function shouldnt be here
        function _checkMarketAndSalesShare(data) {
            let allMarketSales = null
            let allMarketRevenue = null
            let allReviews = 0
            let reviewsCount = 0
            for (const [key, asinObj] of Object.entries(data)) {
                asinObj.revenue = asinObj.sales ? Math.ceil(asinObj.sales * asinObj.price) : null
                if (asinObj.tracked) {
                    allMarketSales += asinObj.sales ? asinObj.sales : null
                    allMarketRevenue += asinObj.revenue
                    allReviews += asinObj.totalReviews
                    reviewsCount++
                }
            }

            for (const [key, asinObj] of Object.entries(data)) {
                asinObj.salesShare = asinObj.sales ? (asinObj.sales / allMarketSales * 100).toFixed(2) : ''
                asinObj.marketShare = asinObj.revenue ? (asinObj.revenue / allMarketRevenue * 100).toFixed(2) : ''
            }
            commit('SET_allMarketSales', allMarketSales)
            commit('SET_allMarketRevenue', allMarketRevenue)
            commit('SET_allReviews', allReviews)
            commit('SET_reviewsCounts', reviewsCount)
            return data
        }

        try {
            let data = await serverApi.getSpecificMarketInfo(marketId)
            data = _checkMarketAndSalesShare(data)
            commit('CLEAR_MARKET_INFO')
            commit('SET_MARKET_INFO', data)
        } catch (e) {
            console.log(e);
        }
    },
    async getUserMarketsList({commit}, marketId) {
        try {
            let data = await serverApi.getUserMarketsDetails(marketId)
            let lastMid = JSON.parse(localStorage.getItem('lastMid'))
            let lastIndex = JSON.parse(localStorage.getItem('lastIndex'))
            // let lastName = JSON.parse(localStorage.getItem('lastName'))
            // let lastKeywords = JSON.parse(localStorage.getItem('lastKeywords'))
            if (this.marketId) commit('CLEAR_CHOSEN_MID')
            commit('CLEAR_MARKETS_LIST')
            commit('SET_MARKETS_LIST', data)
            commit('SET_CHOSEN_MID', lastMid)
            commit('SET_CHOSEN_MID_INDEX', lastIndex)
        } catch (e) {
            console.log(e);
        }
    },
    async commitRequestedArr({state, commit}, arr) {
        try {
            commit('SET_REQUESTED_ARR', arr)
        } catch (e) {
            console.log(e);
        }
    },

    async loadDataToChart({state, commit}, asin, mid) {
        return await serverApi.getAsinChartData(asin, {value: 'sales'}, mid)
    },

    // async set
}
// TODO : WRITE AN ACTION THAT ACTIVATES THE API IN ORDER TO GET THE MARKETS LIST.
