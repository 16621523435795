import serverApi from "../../middleware/serverApi/auth";

export default {
    async loginUser({commit}, userDetails) {
        try {
            let data = await serverApi.loginUser(userDetails)
            commit('LOGIN_USER', data)
            return data;
        } catch (e) {
            console.log(e);
        }
    },

    async registerUser({commit}, userDetails) {
        try {
            let data = await serverApi.registerUser(userDetails)
            localStorage.removeItem('lastMid')
            localStorage.removeItem('lastIndex')
            localStorage.removeItem('lastName')
            localStorage.removeItem('lastKeywords')
            commit('REGISTER_USER', data)
            sessionStorage.setItem('isLogin', "true");
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    },

    async logoutUser({commit}) {
        try {
            await serverApi.logOutUser(); // output === true;
            commit('RESET_USER');
            sessionStorage.removeItem('isLogin');
        } catch (e) {
            return e.message;
        }
    }
}
